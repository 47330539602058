<template>
  <PageContentLayoutOnly>
    <ModernTable
      :filters="filters"
      :rows="budgetTableList"
      :columns="columns"
      :pagination="pagination"
    >
      <template v-slot:header>
        <h3 style="display: inline-block">{{ $t('budgetBuilder.title') }}</h3>

        <div class="p-2">
          <b-button
            type="is-ghost"
            @click="toggleFilters"
            :style="styles.filterButton"
            :class="'filter-button ' + (filters.show ? 'active' : '')"
          >
            <b-icon pack="mdi" icon="filter-outline" size="is-small" style="display: inline-block">
            </b-icon>
            <div style="display: inline-block">Filter</div>
          </b-button>
        </div>

        <nav class="level" :style="{ paddingBottom: '20px' }">
          <div class="level-left">
            <b-button
              tests-id="create-budgetbuilder-button"
              @click="createBudgetModal()"
              size="is-small"
              type="is-primary"
              rounded
            >
              Create Budget
            </b-button>
          </div>
        </nav>
      </template>
    </ModernTable>

    <Modal :toggle.sync="toggle">
      <BudgetModal :toggle="toggle" :budget="selectedBudget"> </BudgetModal>
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import Modal from '@/components/Modal'
import BudgetModal from '@/components/BudgetModal'

import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'BudgetBuilder',
  components: {
    PageContentLayoutOnly,
    ModernTable,
    Modal,
    BudgetModal
  },

  data,

  computed: {
    ...mapGetters('budgets', ['budgetList']),
    ...mapGetters('user', ['authUser']),
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch: {
    hoaId() {
      this.reload()
    },

    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  mounted() {
    this.reload()
  },

  methods,

  i18n: {
    messages: {
      en: { budgetBuilder: { title: 'Budget Builder' } }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';

@media screen and (max-width: $tablet) {
  .filter-button {
    display: none;
  }
}

div.amountFrom {
  width: 60px !important;
}

.filter-button {
  color: #838383 !important;

  &.active {
    color: $link !important;
  }
}

.button.is-ghost {
  background: none;
  border-color: transparent;
  color: #7957d5;
  text-decoration: none;
}
</style>
