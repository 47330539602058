import $ from 'jquery'
import debounce from 'lodash/debounce'

import { ValidationError } from '@/services/errors'
import { notifyError } from '@/services/notify'

export const methods = {
  getPayload() {
    const glAccountId = this.glExpenseDropDownList.filter(i =>
      i.label.includes(this.glExpenseFilterQuery)
    )

    const payload = {
      budgetAccountID:
        this.budgetAccount &&
        this.budgetAccount != undefined &&
        this.budgetAccount.budgetAccountID &&
        this.budgetAccount.budgetAccountID != undefined
          ? this.budgetAccount.budgetAccountID
          : null,
      budgetID: this.budgetID && this.budgetID != undefined ? this.budgetID : 0,
      glAccountID: glAccountId[0] ? glAccountId[0].value : 0,
      note: this.formData.Note && this.formData.Note != undefined ? this.formData.Note : ''
    }

    return payload
  },
  getGlExpenseDropDown() {
    if (this.accountType && this.accountType != undefined) {
      this.$store
        .dispatch('accounting/getGlExpenseList', {
          accountType: this.accountType,
          hasExpenseOnly: false
        })
        .then(() => {
          if (this.glExpenseDropDownList && this.glExpenseDropDownList != undefined) {
            if (this.isDebug == true)
              console.debug('glExpenseDropDownList=' + JSON.stringify(this.glExpenseDropDownList))

            this.data = this.glExpenseDropDownList
          }
        })
    }
  },

  async isValidWithNoDuplicateGls(id, budgetID, updateGlAccountID) {
    let accountInfo = ''
    console.debug('updated gl id=' + updateGlAccountID)
    try {
      await this.$store
        .dispatch('budgets/getBudgetAccountsList', {
          budgetID: budgetID
        })
        .then(() => {
          if (this.budgetAccountTotals && this.budgetAccountTotals != undefined) {
            const results = this.budgetAccountsList.results
            const matchedDuplicate = results.filter(
              i => i.glAccount.accountId === id && i.glAccount.accountId !== updateGlAccountID
            )

            const matchedGlAccountID = matchedDuplicate[0] ? matchedDuplicate[0].glAccountID : 0
            console.debug(
              matchedGlAccountID + ', matchedDuplicate[0]=' + JSON.stringify(matchedDuplicate[0])
            )

            if (matchedGlAccountID > 0) {
              console.debug('matched greater than zero...')
              accountInfo = `${
                matchedDuplicate[0].glAccount.accountNumber
              }-${matchedDuplicate[0].glAccount.subAccountNumber.toString().padStart(3, '0')}-${
                matchedDuplicate[0].glAccount.description
              }`
            }
          }
        })
    } catch (e) {
      console.debug('error in method for retrieving budget accounts:' + e)
    }

    return accountInfo
  },

  async onFormSubmit() {
    if (
      !this.accountType ||
      this.accountType == undefined ||
      this.accountType == '' ||
      !this.budgetID ||
      this.budgetID == undefined ||
      !this.formData ||
      this.formData == undefined
    ) {
      notifyError('There was a problem submitting your budget account.')
      return
    }

    const glAccountId = this.glExpenseDropDownList.filter(i =>
      i.label.includes(this.glExpenseFilterQuery)
    )

    if (!glAccountId || glAccountId == undefined) {
      notifyError('Please select a G/L Account.')
      return
    }

    const payload = this.getPayload()

    const duplicateGlAccountInfo = await this.isValidWithNoDuplicateGls(
      payload.glAccountID,
      payload.budgetID,
      this.buttonText == 'Update' &&
        this.budgetAccount !== undefined &&
        this.budgetAccount.glAccount
        ? this.budgetAccount.glAccount.accountId
        : 0
    )
    console.debug(
      'dup=' +
        duplicateGlAccountInfo +
        ' gl account id=' +
        payload.glAccountID +
        ' budgetID=' +
        payload.budgetID
    )

    if (duplicateGlAccountInfo.length > 0) {
      notifyError(
        `The GL account for ${duplicateGlAccountInfo} is already being used for a budget. Please choose another GL account.`
      )
      return
    }

    try {
      if (this.buttonText == 'Update') {
        const updatePayload = this.getPayload()
        await this.$store.dispatch('budgets/updateBudgetAccount', {
          payload: updatePayload,
          done: () => {
            // auto-close modal
            setTimeout(() => {
              this.$emit('update:toggle', (this.toggle = false))
              $('#clickclose').click()
              this.loading = false
            }, 1000)
          }
        })
      } else {
        //Add

        if (this.isDebug == true)
          console.debug(
            'payload=' + JSON.stringify(payload) + ', formData=' + JSON.stringify(this.formData)
          )

        this.loading = true
        this.$store.dispatch('budgets/addBudgetAccount', {
          budgetID: payload.budgetID,
          glAccountID: payload.glAccountID,
          note: payload.note,
          done: () => {
            // auto-close modal
            setTimeout(() => {
              this.$emit('update:toggle', (this.toggle = false))
              $('#clickclose').click()
              this.loading = false
            }, 1000)
          }
        })
      }
    } catch (e) {
      // If this is a validation error, get the details for each field
      if (e instanceof ValidationError) {
        this.$refs.form.setErrors(e.fields)
      }

      notifyError('There was a problem submitting this budget account.')
    }
    this.loading = false
  },
  getAsyncData: debounce(function(name) {
    // String update
    if (this.name !== name) {
      this.name = name
      this.data = []
    }

    // String cleared
    if (!name.length) {
      this.data = this.glExpenseDropDownList
      return
    }

    this.isFetching = true
    if (this.isDebug == true) console.debug('this.name=' + this.name)

    if (this.glExpenseDropDownList && this.name) {
      this.data = this.glExpenseDropDownList.filter(i =>
        i.label.toLowerCase().includes(this.name.toLowerCase())
      )
    }
    this.isFetching = false
  }, 500),
  getMoreAsyncData: debounce(function() {
    this.getAsyncData(this.name)
  }, 250)
}
