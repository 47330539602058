var field = {
  width: '100px',
  display: 'inline-block',
  paddingRight: '6px',
  textAlign: 'left'
}

export const data = () => ({
  isDebug: true,
  toggle: false,
  loading: false,
  selectedBudgetAccount: null,
  budgetAccountsTableList: [],
  budgetId: 0,
  budgetFiscalYear: 0,
  budgetName: '',
  totals: {},

  filters: {
    show: false
  },

  pagination: {
    perPage: 10
  },

  columns: [
    {
      field: 'accountNumber',
      label: 'Acct #',
      aria: 'Acct #',
      width: '10%',
      numeric: true,
      sortable: true,
      searchable: true
    },
    {
      field: 'category',
      label: 'Category',
      aria: 'Category',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'accountType',
      label: 'Account Type',
      aria: 'Account Type',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'accountName',
      label: 'Account Name',
      aria: 'Account Name',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'twelveMonthActual',
      label: '12 Month Actual',
      aria: '12 Month Actual',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'previousYearBudget',
      label: 'Prev. Year Bdgt',
      aria: 'Prev. Year Bdgt',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'budgeted',
      label: 'Budgeted',
      aria: 'Budgeted',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'budgetedPerMonth',
      label: 'Avg Per Month',
      aria: 'Avg Per Month',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'unitPerMonth',
      label: 'Avg Unit / Month',
      aria: 'Avg Unit / Month',
      width: '10%',
      sortable: true,
      searchable: true
    }
  ],

  styles: {
    formRow: {
      padding: '10px',
      whiteSpace: 'nowrap'
    },
    filterButton: {
      marginTop: '4px'
    },
    amountField: Object.assign({}, field, {
      width: '125px'
    })
  }
})
