import _get from 'lodash/get'
import moment from 'moment'
import { budgetStore } from '@/services/Budgets/store'
import { notifyError } from '@/services/notify'

export const methods = {
  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  determineBudgetAmountRows() {
    if (this.isDebug == true) console.debug('determineBudgetAmountRows()...')

    if (
      this.budgetAmountsList != undefined &&
      this.budgetAmountsList &&
      this.budgetAmountsList != null
    ) {
      try {
        this.budgetAmountsTableList = this.budgetAmountsList.map(i => {
          if (i) {
            console.debug('in determineBudgetAmountRows..' + JSON.stringify(i))

            var periodNumber = _get(i, 'periodNumber', 0)

            console.debug(
              'in this.fiscalYearPeriodList...' + JSON.stringify(this.fiscalYearPeriodList)
            )

            const filterPeriod =
              this.fiscalYearPeriodList &&
              this.fiscalYearPeriodList != undefined &&
              this.fiscalYearPeriodList.periods &&
              this.fiscalYearPeriodList.periods != undefined
                ? this.fiscalYearPeriodList.periods.filter(f => f.periodNumber == periodNumber)
                : null

            if (this.isDebug == true) console.debug('filterPeriod=' + JSON.stringify(filterPeriod))

            var periodName =
              filterPeriod && filterPeriod != undefined && filterPeriod[0]
                ? filterPeriod[0].name
                : ''
            var startDate =
              filterPeriod && filterPeriod != undefined && filterPeriod[0]
                ? moment(filterPeriod[0].startDate).format('MM/DD/YYYY')
                : ''
            var endDate =
              filterPeriod && filterPeriod != undefined && filterPeriod[0]
                ? moment(filterPeriod[0].endDate).format('MM/DD/YYYY')
                : ''
            var amountBudgeted = _get(i, 'amount', 0)
            var lastActual = _get(i, 'lastActualsAmount', 0)
            var lastBudgeted = _get(i, 'lastBudgetAmount', 0)
            var lastActualsPer = _get(i, 'lastActualsPeriod', 0)

            var formatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2
            })

            return {
              periodNumber: periodNumber,
              periodName: periodName,
              startDate: startDate,
              endDate: endDate,
              amountBudgeted: formatter.format(amountBudgeted),
              lastActual: formatter.format(lastActual),
              lastBudgeted: formatter.format(lastBudgeted),
              lastActualsPer: lastActualsPer
            }
          }
        })
      } catch (e) {
        console.debug(e)
      }
    } else {
      console.log('else determineBudgetAmountRows...')
    }

    console.debug('this.budgetAmountsTableList=' + JSON.stringify(this.budgetAmountsTableList))
  },

  async reload() {
    if (!this.budgetAccountId || this.budgetAccountId == 0) {
      this.budgetAccountId =
        this.$route.params.id && this.$route.params != undefined
          ? parseInt(this.$route.params.id)
          : 0
    }

    console.debug('budgetAccountId=' + this.budgetAccountId)

    if (this.budgetFiscalYear == 0) {
      this.budgetFiscalYear =
        this.$route.params != undefined && this.$route.params.budgetFiscalYear
          ? this.$route.params.budgetFiscalYear
          : 0
    }

    console.debug('budgetFiscalYear=' + this.budgetFiscalYear)

    if (this.budgetId == 0) {
      this.budgetId =
        this.$route.params && this.$route.params != undefined
          ? parseInt(this.$route.params.budgetid)
          : 0
    }

    if (this.budgetAccountName == '') {
      this.budgetAccountName =
        this.$route.params && this.$route.params != undefined
          ? `Budget Account: ${this.$route.params.name}`
          : ''
    }

    if (this.budgetName == '') {
      this.budgetName =
        this.$route.params && this.$route.params != undefined
          ? `${this.$route.params.budgetName}`
          : ''
    }

    console.log('params=' + JSON.stringify(this.$route.params))

    this.budgetAmountsTableList = []
    this.totals = {}

    this.loading = true

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    if (this.budgetAccountId && this.budgetAccountId > 0 && this.budgetFiscalYear) {
      try {
        await budgetStore
          .dispatch('getPeriodsByFiscalYear', {
            hoaID: this.hoaId,
            fiscalYear: parseInt(this.budgetFiscalYear)
          })
          .then(async ({ list }) => {
            console.debug('list=' + JSON.stringify(list))

            if (list) {
              this.fiscalYearPeriodList = list

              console.debug(
                'this.fiscalYearPeriodList=' + JSON.stringify(this.fiscalYearPeriodList)
              )

              await budgetStore
                .dispatch('getBudgetAmountsList', {
                  hoaID: this.hoaId,
                  budgetAccountID: this.budgetAccountId
                })
                .then(({ list2 }) => {
                  if (list2) {
                    this.budgetAmountTotals = list2.budgetAmountTotals
                    this.budgetAmountsList = list2.budgetAmountsList.results

                    console.debug('budgetAmountsList=' + JSON.stringify(this.budgetAmountsList))

                    this.determineBudgetAmountRows()

                    if (this.isDebug == true)
                      console.debug(
                        'this.budgetAmountTotals=' + JSON.stringify(this.budgetAmountTotals)
                      )
                    if (this.budgetAmountTotals && this.budgetAmountTotals != undefined) {
                      this.totals = this.budgetAmountTotals
                    }
                  }
                })
            }
          })
      } catch (e) {
        notifyError(e)
      }
    } else {
      if (this.isDebug == true) console.debug('reload this.budgetAccountID <= 0')
    }

    loadingComponent.close()

    this.loading = false
  },

  returnToParent() {
    console.debug('return to budgetaccounts=' + this.budgetFiscalYear)
    this.$router.push({
      name: 'budgetaccounts',
      params: {
        id: this.budgetId,
        name: this.budgetName,
        fiscalYear: this.budgetFiscalYear
      }
    })
  },

  editBudgetAmountModal() {
    this.toggle = true
  }
}
