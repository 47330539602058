var field = {
  width: '100px',
  display: 'inline-block',
  paddingRight: '6px',
  textAlign: 'left'
}

export const data = () => ({
  isDebug: true,
  toggle: false,
  loading: false,
  selectedBudget: null,
  budgetFiscalYear: 0,
  budgetTableList: [],

  filters: {
    show: true
  },

  pagination: {
    perPage: 10
  },

  columns: [
    {
      field: 'fiscalYear',
      label: 'Year',
      aria: 'Year',
      width: '10%',
      numeric: true,
      sortable: true,
      searchable: true
    },
    {
      field: 'name',
      label: 'Budget Name',
      width: '70%',
      sortable: true,
      searchable: true
    },
    {
      field: 'isAuthoritative',
      label: 'Authoritative',
      width: '5%',
      sortable: true,
      searchable: true
    }
  ],

  styles: {
    formRow: {
      padding: '10px',
      whiteSpace: 'nowrap'
    },
    filterButton: {
      float: 'right',
      marginTop: '4px'
    },
    amountField: Object.assign({}, field, {
      width: '125px'
    })
  }
})
