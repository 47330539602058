<template>
  <PageContentLayoutOnly>
    <ModernTable
      :filters="filters"
      :rows="budgetAmountsTableList"
      :columns="columns"
      :pagination="pagination"
    >
      <template v-slot:header>
        <div class="level">
          <div class="level-left level-item title">{{ $t('budgetAmounts.title') }}</div>
          <div class="level-right" :style="{ display: 'flex', alignItems: 'center' }">
            <div class="level-item" :style="{ marginBottom: 0 }">
              <svg
                width="30"
                height="30"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 19l-7-7 7-7"
                ></path>
              </svg>
            </div>
            <div class="level-item">
              <b-button label="Back to Budget Accounts" @click.prevent="returnToParent()" />
            </div>
          </div>
        </div>
        <div class="subtitle">{{ budgetAccountName || '' }}</div>
        <div class="p-2">
          <b-button
            type="is-ghost"
            @click="toggleFilters"
            :style="styles.filterButton"
            :class="'filter-button ' + (filters.show ? 'active' : '')"
          >
            <b-icon pack="mdi" icon="filter-outline" size="is-small" style="display: inline-block">
            </b-icon>
            <div style="display: inline-block">Filter</div>
          </b-button>
        </div>
        <nav class="level" :style="{ marginBottom: '12px' }">
          <div class="level-left">
            <b-button
              tests-id="edit-budgetamount-button"
              @click="editBudgetAmountModal()"
              size="is-small"
              type="is-primary"
              rounded
            >
              Edit Budget Amounts
            </b-button>
          </div>
        </nav>
      </template>
    </ModernTable>
    <div class="pt-5 box" style="background-color: #f8f9fa;" v-if="totals && totals != undefined">
      <div class="columns">
        <div class="font-bold column"></div>
        <div class="font-bold column"></div>
        <div class="font-bold column pl-6"></div>
        <div class="font-bold column has-text-right">
          Budgeted
        </div>
        <div class="font-bold column has-text-right">
          Last Actual
        </div>
        <div class="font-bold column has-text-right">
          Last Budgeted
        </div>
        <div class="font-bold column"></div>
        <div class="font-bold column"></div>
      </div>
      <div class="columns">
        <div class="font-bold column"></div>
        <div class="font-bold column"></div>
        <div class="font-bold column pl-6 has-text-right">Totals:</div>
        <div class="column has-text-right">
          {{ `${totals.totalAmountBudgeted || 0}` | currency }}
        </div>
        <div class="column has-text-right">
          {{ `${totals.totalLastActuals || 0}` | currency }}
        </div>
        <div class="column has-text-right">
          {{ `${totals.totalLastBudgeted || 0}` | currency }}
        </div>
        <div class="font-bold column"></div>
        <div class="font-bold column"></div>
      </div>
    </div>
    <Modal :toggle.sync="toggle">
      <template v-slot:header>Budget Amount Editor</template>

      <BudgetAmountModal
        :toggle="toggle"
        :budgetAccountID="parseInt(budgetAccountId)"
        :budgetAmountList="
          budgetAmountsList && budgetAmountsList != undefined ? budgetAmountsList : null
        "
        :budgetFiscalYear="parseInt(budgetFiscalYear)"
      >
      </BudgetAmountModal>
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState } from 'vuex'

import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import Modal from '@/components/Modal'
import BudgetAmountModal from '@/components/BudgetAmountModal'

import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'BudgetAmounts',
  components: {
    PageContentLayoutOnly,
    ModernTable,
    Modal,
    BudgetAmountModal
  },

  data,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch: {
    hoaId() {
      this.reload()
    },

    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  mounted() {
    this.reload()
  },

  methods,

  i18n: {
    messages: {
      en: { budgetAmounts: { title: 'Budget Amounts' } }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';

@media screen and (max-width: $tablet) {
  .filter-button {
    display: none;
  }
}

div.amountFrom {
  width: 60px !important;
}

.filter-button {
  color: #838383 !important;

  &.active {
    color: $link !important;
  }
}

.button.is-ghost {
  background: none;
  border-color: transparent;
  color: #7957d5;
  text-decoration: none;
}
</style>
