<template>
  <div>
    <div class="is-size-4" v-if="buttonText == 'Update'">Update Budget Account</div>
    <div class="is-size-4" v-else>Create Budget Account</div>

    <div>
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <transition name="fade" mode="out-in" appear>
          <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
            <fieldset>
              <div class="column is-8" v-if="accountTypeIncomeExpenseList">
                <valid-select
                  label="Account Type"
                  placeholder="Account Type"
                  vid="accountType"
                  v-model="accountType"
                  aria-required="The account type is required"
                  rules="required"
                >
                  <option
                    v-for="option in accountTypeIncomeExpenseList"
                    :value="option.value"
                    :key="option.value"
                    >{{ option.label }}</option
                  >
                </valid-select>
              </div>
              <div class="column is-12" :aria-label="`Select G/L ${accountType}`">
                <label class="label">{{ `Select G/L ${accountType}` }}</label>
                <b-autocomplete
                  size="is-small"
                  field="label"
                  icon="magnify"
                  :loading="isFetching"
                  :placeholder="`e.g. G/L ${accountType}`"
                  :data="data"
                  :open-on-focus="openOnFocus"
                  v-model="glExpenseFilterQuery"
                  required="true"
                  rules="required"
                  @typing="getAsyncData"
                  @select="option => (selected = option)"
                >
                </b-autocomplete>
              </div>
              <div class="column is-12">
                <valid-input
                  style="width:50px !important;"
                  name="Note"
                  label="Note"
                  maxlength="8000"
                  type="textarea"
                  vid="Note"
                  placeholder="Note"
                  spellcheck="true"
                  aria-label="Note"
                  rules="max:8000|required"
                  class="is-small"
                  v-model="formData.Note"
                />
              </div>
              <div class="pb-5">
                <button
                  tests-id="save-budget-account-button"
                  type="submit"
                  :disabled="loading"
                  class="button is-primary is-rounded"
                >
                  {{ buttonText }}
                </button>
              </div>
            </fieldset>
            <fieldset>
              <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                <div class="field">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </fieldset>
          </form>
        </transition>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { data, PRESET_FORM_VALUES } from './keys/data'
import { methods } from './keys/methods'
//
// components
import ValidInput from '@/components/inputs/ValidInput'
import ValidSelect from '@/components/inputs/ValidSelect'
//
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
//
//
export default {
  name: 'BudgetAccountModal',

  props: {
    budgetID: Number,
    budgetAccount: Object
  },

  components: {
    ValidInput,
    ValidSelect,
    ValidationObserver,
    ValidationProvider
  },

  data,
  methods,

  watch: {
    hoaId() {
      this.reload()
    },

    accountType() {
      if (this.accountType) {
        this.getGlExpenseDropDown()
        this.toggle = false
      }
    }
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    }),
    ...mapGetters('accounting', ['accountTypeIncomeExpenseList']),
    ...mapGetters('accounting', ['glExpenseDropDownList']),
    ...mapGetters('budgets', ['budgetAccountsList', 'budgetAccountTotals']),
    glExpenseFilterSuggestions() {
      if (
        this.glExpenseFilterQuery &&
        this.glExpenseDropDownList &&
        this.glExpenseDropDownList != undefined
      ) {
        return this.glExpenseDropDownList.filter(
          a => a.label.indexOf(this.glExpenseFilterQuery.toLowerCase()) !== -1
        )
      } else {
        return this.glExpenseDropDownList
      }
    }
  },

  mounted() {
    this.$store.dispatch('accounting/getAccountTypeExpenseIncomeList')
    //this.$store.dispatch('accounting/getGlExpenseList')

    this.loading = true

    if (
      this.budgetAccount &&
      this.budgetAccount.budgetAccountID != undefined &&
      this.budgetAccount.budgetAccountID > 0
    ) {
      if (this.isDebug == true)
        console.debug('mounted budget =' + JSON.stringify(this.budgetAccount))
      this.formData.Note = this.budgetAccount.note
      this.accountType = this.budgetAccount.glAccount.accountType

      this.glExpenseFilterQuery = this.budgetAccount.glAccount
        ? this.budgetAccount.glAccount.accountNumber +
          '-' +
          this.budgetAccount.glAccount.subAccountNumber.toString().padStart(3, '0') +
          ' - ' +
          this.budgetAccount.glAccount.description
        : ''
      this.buttonText = 'Update'
    } else {
      this.formData = { ...PRESET_FORM_VALUES }
      this.$refs.form.reset()

      this.buttonText = 'Add'
    }

    this.loading = false
  }
}
</script>

<style lang="scss">
#budget-account-modal .step-details {
  background-color: #fff !important;
  padding-left: 0.25rem !important;
  padding-right: 0.5rem !important;
}

#budget-account-modal .b-steps.is-small .steps {
  margin-top: 1rem;
}

#budget-account-modal .b-steps .steps + .step-content {
  padding-top: 0 !important;
}

#budget-account-modal .step-navigation {
  display: flex;
  justify-content: flex-end;
}

#budget-account-modal .my-grid-class {
  height: 400px;
}

#budget-account-modal tbody {
  min-height: 100px !important;
  overflow: auto !important;
}
</style>
