export const PRESET_FORM_VALUES = Object.freeze({
  note: ''
})
//
export const data = function() {
  return {
    formComplete: false,
    formData: {
      ...PRESET_FORM_VALUES
    },
    loading: false,
    isDebug: true,
    selectedRow: null,
    buttonText: 'Add',
    accountType: '',
    openOnFocus: true,
    glExpenseFilterQuery: null,
    isFetching: false,
    data: [],
    styles: {
      formRow: {
        padding: '10px',
        whiteSpace: 'nowrap'
      }
    }
  }
}
