<template>
  <div>
    <div class="is-size-4" v-if="buttonText == 'Update'">Update Budget</div>
    <div class="is-size-4" v-else>Create Budget</div>
    <div>
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <transition name="fade" mode="out-in" appear>
          <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
            <fieldset>
              <div class="column is-12" :style="styles.formRow">
                <b-field label="Fiscal Year">
                  <b-numberinput
                    name="FiscalYear"
                    vid="fiscalYear"
                    v-model="formData.fiscalYear"
                    required
                  />
                </b-field>
              </div>
              <div class="column is-12">
                <valid-input
                  id="cypressBudgetName"
                  style="width:50px !important;"
                  name="BudgetName"
                  label="Budget Name"
                  maxlength="100"
                  type="text"
                  vid="budgetName"
                  placeholder="Budget Name"
                  spellcheck="true"
                  aria-label="BudgetName"
                  class="is-small"
                  rules="max:100|required"
                  v-model="formData.BudgetName"
                />
              </div>
              <div class="column is-12">
                <b-checkbox class="has-left-text" v-model="formData.IsAuthoritative"
                  >Authoritative</b-checkbox
                >
              </div>
              <div class="pb-5 pt-5">
                <button
                  type="submit"
                  tests-id="budgetbuilder-modal-button"
                  :disabled="loading"
                  class="button is-primary is-rounded"
                >
                  {{ buttonText }}
                </button>
              </div>
            </fieldset>
            <fieldset>
              <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                <div class="field">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </fieldset>
          </form>
        </transition>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
// Components
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ValidationError } from '@/services/errors'
import { ToastProgrammatic as Toast } from 'buefy'
import { mapState } from 'vuex'
import $ from 'jquery'
import moment from 'moment'

// components
import ValidInput from '@/components/inputs/ValidInput'

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

let DEFAULT_FORM_VALUES = {
  fiscalYear: 0,
  budgetName: '',
  isAuthoritative: false
}

export default {
  name: 'BudgetModal',

  props: {
    budget: Object
  },

  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider
  },

  data: function() {
    return {
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      },
      loading: false,
      isDebug: true,
      selectedRow: null,
      buttonText: 'Add',
      styles: {
        formRow: {
          padding: '10px',
          whiteSpace: 'nowrap'
        }
      }
    }
  },

  created() {},

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  mounted() {
    this.loading = true

    if (this.budget && this.budget.budgetID != undefined && this.budget.budgetID > 0) {
      if (this.isDebug == true) console.debug('mounted budget =' + JSON.stringify(this.budget))
      this.formData.fiscalYear = this.budget.fiscalYear
      this.formData.BudgetName = this.budget.name
      this.formData.IsAuthoritative = this.budget.isAuthoritative
      this.buttonText = 'Update'
    } else {
      this.formData = { ...DEFAULT_FORM_VALUES }
      this.$refs.form.reset()

      const today = moment()
      var year = moment(today).format('YYYY')
      this.formData.fiscalYear = parseInt(year)

      this.buttonText = 'Add'
    }

    this.loading = false
  },

  methods: {
    getPayload() {
      if (this.isDebug == true) console.debug('this.formData=' + JSON.stringify(this.formData))

      const payload = {
        budgetID:
          this.budget && this.budget.budgetID && this.budget.budgetID != undefined
            ? this.budget.budgetID
            : 0,
        fiscalYear:
          this.formData.fiscalYear && this.formData.fiscalYear != undefined
            ? this.formData.fiscalYear
            : 0,
        name:
          this.formData.BudgetName && this.formData.BudgetName != undefined
            ? this.formData.BudgetName
            : '',
        hoaId: this.hoaId,
        numberOfPeriods: 12,
        isAuthoritative:
          this.formData.IsAuthoritative && this.formData.IsAuthoritative != undefined
            ? this.formData.IsAuthoritative
            : false
      }

      return payload
    },
    async onFormSubmit() {
      if (!this.formData || (this.formData == undefined && this.budgetID && this.budgetID > 0)) {
        notifyError('There was a problem submitting your budget.')
        return
      }

      try {
        if (this.buttonText == 'Update') {
          const updatePayload = this.getPayload()
          this.$store.dispatch('budgets/updateBudget', {
            payload: updatePayload,
            done: () => {
              // auto-close modal
              this.$emit('update:toggle', (this.toggle = false))
              $('#clickclose').click()
              this.loading = false
            }
          })
        } else {
          //Add
          const payload = this.getPayload()

          if (this.isDebug == true) console.debug('payload=' + JSON.stringify(payload))

          let validationMessages = ''

          if (payload.fiscalYear <= 0 || payload.fiscalYear.toString().trim().length != 4) {
            validationMessages = '<div>Please enter valid year.</div>'
          }

          if (payload.budgetName < 0) {
            validationMessages += '<div>Please enter a budget name.</div>'
          }

          if (validationMessages != '') {
            notifyError(validationMessages)
            return
          }

          this.loading = true
          this.$store.dispatch('budgets/addBudget', {
            fiscalYear: payload.fiscalYear,
            name: payload.name,
            hoaID: this.hoaId,
            numberOfPeriods: payload.numberOfPeriods,
            isAuthoritative: payload.isAuthoritative,
            done: () => {
              // auto-close modal
              this.$emit('update:toggle', (this.toggle = false))
              $('#clickclose').click()
              this.loading = false
            }
          })
        }
      } catch (e) {
        // If this is a validation error, get the details for each field
        if (e instanceof ValidationError) {
          this.$refs.form.setErrors(e.fields)
        }

        notifyError('There was a problem submitting this budget.')
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.step-details {
  background-color: #fff !important;
  padding-left: 0.25rem !important;
  padding-right: 0.5rem !important;
}

.b-steps.is-small .steps {
  margin-top: 1rem;
}

.b-steps .steps + .step-content {
  padding-top: 0 !important;
}

.step-navigation {
  display: flex;
  justify-content: flex-end;
}

.my-grid-class {
  height: 400px;
}

tbody {
  min-height: 100px !important;
  overflow: auto !important;
}
</style>
