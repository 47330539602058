<template>
  <div>
    <div>
      <div class="is-size-4" v-if="buttonText == 'Update'">Update Budget Amounts</div>
      <div
        class="is-12"
        v-if="
          fiscalYearPeriodList &&
            fiscalYearPeriodList != undefined &&
            fiscalYearPeriodList.periods &&
            fiscalYearPeriodList.periods != undefined
        "
      >
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <div class="columns" v-if="breakpoint === 'desktop'">
                <div class="column is-2" aria-label="Period #">
                  <label class="label">Period #</label>
                </div>
                <div class="column is-3" aria-label="Period">
                  <label class="label">Period</label>
                </div>
                <div class="column is-3" aria-label="Period">
                  <label class="label">Year</label>
                </div>
                <div class="column is-3" aria-label="Amount">
                  <label class="label">Amount</label>
                </div>
              </div>

              <div class="columns">
                <div class="column is-8 is-right">
                  <valid-input
                    name="ApplyToAllAmount"
                    type="text"
                    vid="ApplyToAllAmount"
                    placeholder="Apply to All Periods"
                    aria-label="Apply to All Periods"
                    class="is-small"
                    v-model="applied"
                    onkeypress="return (event.charCode >= 48 || event.charCode == 46) && event.charCode <= 57"
                  />
                </div>
                <div class="column is-3">
                  <b-button
                    type="btn btn-outline-secondary btn-sm is-small is-right"
                    @click="confirmApplyToAll()"
                    >Apply to All Periods</b-button
                  >
                </div>
              </div>

              <div
                v-for="(c, index) in fiscalYearPeriodList.periods"
                :key="index"
                fiscal-year-period
                :class="breakpoint !== 'desktop' ? 'card' : ''"
                :style="{ marginBottom: '12px', padding: '8px' }"
              >
                <fieldset>
                  <div class="columns">
                    <div class="column is-2" aria-label="Period #">
                      <label class="label" v-if="breakpoint !== 'desktop'">Period #</label>
                      <valid-input
                        tests-id="input-period-number"
                        :aria-readonly="true"
                        :aria-disabled="true"
                        disabled="true"
                        name="PeriodNumber"
                        type="text"
                        vid="PeriodNumber"
                        placeholder="Period #"
                        aria-label="Period #"
                        class="is-small"
                        :value="c.periodNumber"
                      />
                    </div>
                    <div class="column is-3">
                      <label class="label" v-if="breakpoint !== 'desktop'">Period</label>
                      <valid-input
                        :aria-readonly="true"
                        :aria-disabled="true"
                        disabled="true"
                        name="Period"
                        type="text"
                        vid="Period"
                        placeholder="Period"
                        aria-label="Period"
                        class="is-small"
                        :value="c.monthName"
                      />
                    </div>
                    <div class="column is-3">
                      <label class="label" v-if="breakpoint !== 'desktop'">Year</label>
                      <valid-input
                        :aria-readonly="true"
                        :aria-disabled="true"
                        disabled="true"
                        name="Year"
                        type="text"
                        vid="Year"
                        placeholder="Year"
                        aria-label="Year"
                        class="is-small"
                        :value="c.monthYear"
                      />
                    </div>
                    <div class="column is-3">
                      <label class="label" v-if="breakpoint !== 'desktop'">Amount</label>
                      <valid-input
                        :name="`Amount${c.budgetAmountID}`"
                        type="text"
                        :vid="`Amount${c.budgetAmountID}`"
                        placeholder="Amount"
                        aria-label="TestAmount"
                        rules="required"
                        class="is-small"
                        :value="c.amount"
                        v-model="form.amount[index]"
                        onkeypress="return (event.charCode >= 48 || event.charCode == 46) && event.charCode <= 57"
                      />

                      <input type="hidden" v-model="form.amountId[index]" />
                      <input type="hidden" v-model="form.amountPeriod[index]" />
                    </div>
                  </div>
                </fieldset>
              </div>
              <div class="pb-5 pr-6" :style="{ display: 'flex', justifyContent: 'end' }">
                <button type="submit" :disabled="loading" class="button is-primary is-rounded">
                  {{ buttonText }}
                </button>
              </div>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import { formatCurrency } from '@/filters'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ValidationError } from '@/services/errors'
import { mapState, mapGetters } from 'vuex'
import { notifyError } from '@/services/notify'
import $ from 'jquery'

// components
import ValidInput from '@/components/inputs/ValidInput'

let DEFAULT_FORM_VALUES = {}

export default {
  name: 'BudgetAmountModal',

  props: {
    budgetAccountID: Number,
    budgetAmountList: Array,
    budgetFiscalYear: Number
  },

  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider
  },

  data: function() {
    return {
      applied: 0,
      maxPeriods: 0,
      form: {
        amount: [],
        amountId: [],
        amountPeriod: []
      },
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      },
      loading: false,
      isDebug: true,
      selectedRow: null,
      buttonText: 'Add',
      accountType: '',
      styles: {
        formRow: {
          padding: '10px',
          whiteSpace: 'nowrap'
        }
      },
      fiscalPeriodLength: 0
    }
  },

  watch: {
    hoaId() {
      this.reload()
    },

    accountType() {
      if (this.accountType) {
        this.getGlExpenseDropDown()
        this.toggle = false
      }
    }
  },

  created() {},

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      breakpoint: state => state.win.breakpoint
    }),
    ...mapGetters('budgets', ['fiscalYearPeriodList'])
  },

  mounted() {
    this.loading = true

    if (this.isDebug == true) console.debug('this.budgetFiscalYear=' + this.budgetFiscalYear)

    if (this.budgetFiscalYear && this.budgetFiscalYear != undefined && this.budgetFiscalYear > 0) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })

      this.$store
        .dispatch('budgets/getPeriodsByFiscalYear', {
          fiscalYear: parseInt(this.budgetFiscalYear)
        })
        .then(() => {
          if (this.isDebug == true)
            console.debug('periods=' + JSON.stringify(this.fiscalYearPeriodList))

          //Merged fiscal periods into budget amount list
          if (
            this.budgetAmountList &&
            this.budgetAmountList != undefined &&
            this.fiscalYearPeriodList &&
            this.fiscalYearPeriodList != undefined &&
            this.fiscalYearPeriodList.periods &&
            this.fiscalYearPeriodList.periods != undefined &&
            this.form
          ) {
            this.fiscalPeriodLength = this.fiscalYearPeriodList.periods.length

            let counter = 0
            Array.from(this.budgetAmountList).forEach(element => {
              if (element && element != undefined) {
                if (
                  element &&
                  element != undefined &&
                  element.periodNumber &&
                  element.budgetAmountID
                ) {
                  const filterPeriod = this.fiscalYearPeriodList.periods.filter(
                    f => f.periodNumber == element.periodNumber
                  )

                  if (this.isDebug == true)
                    console.debug('filterPeriod=' + JSON.stringify(filterPeriod))

                  if (filterPeriod) {
                    this.form.amount[counter] = element.amount
                    this.form.amountId[counter] = element.budgetAmountID
                    this.form.amountPeriod[counter] = element.periodNumber
                  }

                  counter += 1
                }
              }
            })

            //Add items for missing periods for inserts
            const maxPeriods = this.fiscalPeriodLength
            const startCounter = counter
            for (let i = startCounter; i <= maxPeriods; i++) {
              const period = i + 1
              this.form.amount[i] = 0
              this.form.amountId[i] = 0
              this.form.amountPeriod[i] = period
            }

            this.buttonText = 'Update'
          }
        })
      loadingComponent.close()
    } else {
      if (this.isDebug == true) console.debug('else no fiscal year=' + this.budgetFiscalYear)
    }

    this.loading = false
  },

  methods: {
    getPayload(periodNumber, id, amount) {
      const payload = {
        budgetAmountID: id && id !== undefined ? id : 0,
        amount: amount && amount !== undefined ? amount : 0,
        periodNumber: periodNumber && periodNumber !== undefined ? periodNumber : 0
      }

      console.debug('getPayload=' + JSON.stringify(payload))

      return payload
    },

    preventNonNumericKeyPress() {
      return (event.charCode >= 48 || event.charCode == 46) && event.charCode <= 57
    },

    checkValidNumberFormat(numberInput, period) {
      console.debug('checkValidNumberFormat...')

      //Left off here. check for number
      if (typeof numberInput !== 'number') {
        return `Please enter a valid number for period ${period}: ${numberInput}`
      } else {
        return ''
      }
    },

    async confirmApplyToAll() {
      if (!this.applied || this.applied <= 0) {
        notifyError('Please enter an amount to apply to each period that is greater than zero.')
        return
      }

      this.$buefy.dialog.confirm({
        title: 'Apply Amount to All Periods',
        message: `Are you sure you want to <b>apply</b> ${formatCurrency(
          this.applied
        )} to each period?`,
        confirmText: 'Apply to All Periods',
        type: 'is-info',
        hasIcon: true,
        onConfirm: async () => await this.applyToAllPeriods()
      })
    },

    async applyToAllPeriods() {
      console.debug('applyToAllPeriods...' + this.applied + ', maxPeriods')

      for (let i = 0; i < 12; i++) {
        this.form.amount[i] = this.applied
      }

      await this.onFormSubmit()
    },

    async onFormSubmit() {
      console.log(
        'formData=' +
          JSON.stringify(this.formData) +
          ', form.amount=' +
          JSON.stringify(this.form.amount) +
          ', form.amountId=' +
          JSON.stringify(this.form.amountId) +
          ', form.amountPeriod=' +
          JSON.stringify(this.form.amountPeriod)
      )

      if (!this.formData || this.formData == undefined) {
        notifyError('There was a problem submitting your budget amount(s).')
        return
      }

      let submittedAmounts = []

      let formCounter = 0
      let notifyErrorMessage = ''

      let amountMessage = ''

      this.form.amount.forEach(element => {
        if (element && element != undefined) {
          const amount =
            this.applied > 0
              ? this.applied
              : this.form.amount[formCounter]
              ? this.form.amount[formCounter]
              : 0
          submittedAmounts.push(
            this.getPayload(
              this.form.amountPeriod[formCounter]
                ? this.form.amountPeriod[formCounter]
                : formCounter + 1,
              this.form.amountId[formCounter],
              amount
            )
          )

          formCounter += 1
        }
      })

      if (amountMessage !== '') {
        notifyError(amountMessage)
        return
      }

      if (formCounter < 12) {
        for (let i = formCounter; i <= 12; i++) {
          submittedAmounts.push(this.getPayload(formCounter, 0, 0))

          formCounter += 1
        }
      }

      console.debug('submittedAmounts=' + JSON.stringify(submittedAmounts))

      if (notifyErrorMessage.length > 0) {
        notifyError(notifyErrorMessage)
        return
      }

      const processedPayload = {
        budgetAccountID:
          this.budgetAccountID && this.budgetAccountID != undefined ? this.budgetAccountID : null,
        budgetAmounts: submittedAmounts
      }

      try {
        this.loading = true
        await this.$store.dispatch('budgets/upsertBudgetAmount', {
          payload: processedPayload,
          done: ({ result }) => {
            if (result) {
              // auto-close modal
              this.$emit('update:toggle', (this.toggle = false))
              $('#clickclose').click()
              this.loading = false
            }
          }
        })
      } catch (e) {
        // If this is a validation error, get the details for each field
        if (e instanceof ValidationError) {
          this.$refs.form.setErrors(e.fields)
        }

        notifyError('There was a problem submitting this budget amount(s).')
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.step-details {
  background-color: #fff !important;
  padding-left: 0.25rem !important;
  padding-right: 0.5rem !important;
}

.b-steps.is-small .steps {
  margin-top: 1rem;
}

.b-steps .steps + .step-content {
  padding-top: 0 !important;
}

.step-navigation {
  display: flex;
  justify-content: flex-end;
}

.my-grid-class {
  height: 400px;
}

tbody {
  min-height: 100px !important;
  overflow: auto !important;
}
</style>
