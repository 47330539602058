import Button from '@/components/buttons/Button'
import _get from 'lodash/get'
import { ToastProgrammatic as Toast } from 'buefy'
import { base64toBlob, showPdf } from '@/services/blobutility'
import { budgetStore } from '@/services/Budgets/store'

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

export const methods = {
  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  confirmDeleteBudgetAccount(id, name) {
    this.$buefy.dialog.confirm({
      title: 'Deleting Budget Account',
      message: `Are you sure you want to <b>delete</b> the Budget Account for ${name}?`,
      confirmText: 'Delete Budget Account',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteBudgetAccount(id)
    })
  },

  determineBudgetAccountRows() {
    if (this.isDebug == true) console.debug('determineBudgetAccountRows()...')

    if (
      this.budgetAccountsList &&
      this.budgetAccountsList != undefined &&
      this.budgetAccountsList.results &&
      this.budgetAccountsList.results != undefined
    ) {
      this.budgetAccountsTableList = this.budgetAccountsList.results.map(i => {
        var budgetAccountId = _get(i, 'budgetAccountID', 0)
        var category = _get(i, 'glAccount.fundType', 0)
        var accountNumber = _get(i, 'glAccount.accountNumber', 0)
        var accountType = _get(i, 'glAccount.accountType', '')
        var accountName = _get(i, 'glAccount.description', '')
        var twelveMonthActual = _get(i, 'twelveMonthActualTotal', 0)
        var previousYearBudget = _get(i, 'previousYearAnnualBudgetTotal', 0)
        var budgeted = _get(i, 'currentYearAnnualBudgetTotal', 0)
        var budgetedPerMonth = _get(i, 'budgetedPerMonth', 0)
        var budgetedUnitPerMonth = _get(i, 'budgetedUnitPerMonth', 0)

        var formatter = new Intl.NumberFormat('en-US', {
          style: 'decimal',
          minimumFractionDigits: 2
        })

        return {
          accountNumber: accountNumber,
          category: category,
          accountType: accountType,
          accountName: accountName,
          twelveMonthActual: formatter.format(twelveMonthActual),
          previousYearBudget: formatter.format(previousYearBudget),
          budgeted: formatter.format(budgeted),
          budgetedPerMonth: formatter.format(budgetedPerMonth),
          unitPerMonth: formatter.format(budgetedUnitPerMonth),
          account: {
            component: Button,
            props: {
              onClick: () => {
                this.processAmountDetail(i)
              },
              text: 'Amounts'
            }
          },
          edit: {
            component: Button,
            props: {
              onClick: () => {
                this.processBudgetAccountUpdate(i)
              },
              text: 'Edit'
            }
          },
          erase: {
            component: Button,
            props: {
              onClick: () => {
                this.confirmDeleteBudgetAccount(budgetAccountId, accountName)
              },
              text: 'Delete'
            }
          }
        }
      })
    } else {
      console.log('else determineBudgetAccountRows...')
    }
  },

  async viewBudgetSummaryReport() {
    console.debug('viewBudgetSummaryReport...')
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    const params = {
      hoaID: this.hoaId,
      budgetID: this.budgetId,
      format: 'PDF'
    }

    try {
      await budgetStore
        .dispatch('runBudgetSummaryBase64Report', {
          params
        })
        .then(({ returned }) => {
          loadingComponent.close()

          if (returned && returned != undefined) {
            if (this.isDebug == true) console.debug('base64pdf........................' + returned)

            const contentType = 'application/pdf'
            const blob = base64toBlob(returned, contentType)

            showPdf(blob)
          }
          return
        })
    } catch (e) {
      console.debug('error in method for retrieving the budget summary report: ' + e)
    }
    loadingComponent.close()
  },

  async reload() {
    if (this.isDebug == true) console.debug('route params=' + JSON.stringify(this.$route.params))

    if (this.budgetId == 0) {
      this.budgetId =
        this.$route.params && this.$route.params != undefined ? parseInt(this.$route.params.id) : 0
    }

    if (this.budgetName == '') {
      this.budgetName =
        this.$route.params && this.$route.params != undefined ? this.$route.params.name : ''
    }

    if (this.budgetFiscalYear == 0) {
      this.budgetFiscalYear =
        this.$route.params && this.$route.params != undefined ? this.$route.params.fiscalYear : 0
    }

    this.budgetAccountsTableList = []
    this.totals = {}

    this.loading = true

    if (this.budgetId && this.budgetId > 0) {
      try {
        this.$store
          .dispatch('budgets/getBudgetAccountsList', {
            budgetID: this.budgetId
          })
          .then(() => {
            this.determineBudgetAccountRows()

            if (this.isDebug == true)
              console.debug('this.budgetAccountTotals=' + JSON.stringify(this.budgetAccountTotals))
            if (this.budgetAccountTotals && this.budgetAccountTotals != undefined) {
              this.totals = this.budgetAccountTotals
            }
          })
      } catch (e) {
        console.debug('error in method for retrieving budget accounts:' + e)
      }
    } else {
      if (this.isDebug == true) console.debug('reload this.budgetID <= 0')
    }

    this.loading = false
  },

  returnToParent() {
    this.$router.push({
      name: 'budgetbuilder'
    })
  },

  processBudgetAccountUpdate(budgetAccount) {
    this.toggle = true
    this.selectedBudgetAccount = budgetAccount
  },

  processAmountDetail(budgetAccount) {
    const name = `${budgetAccount.glAccount.fundType} - ${budgetAccount.glAccount.accountNumber} - ${budgetAccount.glAccount.accountType} - ${budgetAccount.glAccount.description}`

    this.$router.push({
      name: 'budgetamounts',
      params: {
        id: budgetAccount.budgetAccountID,
        name: name,
        budgetid: budgetAccount.budgetID,
        budgetFiscalYear: this.budgetFiscalYear,
        budgetName: this.budgetName
      }
    })
  },

  createBudgetAccountModal() {
    if (this.isDebug == true) console.debug('in createBudgetAccountModal...')
    this.selectedBudgetAccount = null
    this.toggle = true
  },

  async deleteBudgetAccount(budgetAccountId) {
    if (this.isDebug == true) console.debug('...in deleteBudgetAccount id=' + budgetAccountId)

    this.loading = true

    if (budgetAccountId <= 0) {
      notifyError('There was a problem deleting your budget account.')
      return
    }

    try {
      this.$store.dispatch('budgets/deleteBudgetAccount', {
        budgetAccountID: budgetAccountId,
        done: () => {
          this.loading = false
          this.reload()
        }
      })
    } catch (e) {
      console.debug('There was an issue deleting your budget account.')
    }

    this.loading = false
  }
}
