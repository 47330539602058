import Button from '@/components/buttons/Button'
import _get from 'lodash/get'
import { ToastProgrammatic as Toast } from 'buefy'

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

export const methods = {
  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  confirmDeleteBudget(id, name, isAuthoratative) {
    this.$buefy.dialog.confirm({
      title: 'Deleting Budget',
      message: `Are you sure you want to <b>delete</b> the Budget for, "${name}"?`,
      confirmText: 'Delete Budget',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteBudget(id, isAuthoratative)
    })
  },

  confirmCopyBudget(copyFromBudget) {
    this.$buefy.dialog.prompt({
      message: `Are you sure you want to <b>copy</b> the Budget for, "${copyFromBudget.name}"?`,
      inputAttrs: {
        placeHolder: 'New Fiscal Year',
        maxlength: 4
      },
      confirmText: 'Copy',
      trapFocus: true,
      onConfirm: value => this.copyBudget(copyFromBudget, value)
    })
  },

  determineBudgetRows() {
    if (this.isDebug == true) console.debug('determineBudgetRows()...')

    if (this.budgetList) {
      this.budgetTableList = this.budgetList.map(i => {
        var budgetId = _get(i, 'budgetID', '')
        var tempIsAuthoratative = _get(i, 'isAuthoritative', false)
        var isAuthoritative = tempIsAuthoratative && tempIsAuthoratative == true ? 'Yes' : 'No'
        var budgetName = _get(i, 'name', '')

        return {
          fiscalYear: _get(i, 'fiscalYear', 0),
          name: budgetName,
          isAuthoritative: isAuthoritative,
          copy: {
            component: Button,
            props: {
              onClick: () => {
                this.confirmCopyBudget(i)
              },
              text: 'Copy',
              testsid: 'copy-budgetbuilder-button'
            }
          },
          account: {
            component: Button,
            props: {
              onClick: () => {
                this.processAccountDetail(i)
              },
              text: 'Accounts'
            }
          },
          edit: {
            component: Button,
            props: {
              onClick: () => {
                this.processBudgetUpdate(i)
              },
              text: 'Edit'
            }
          },
          erase: {
            component: Button,
            props: {
              onClick: () => {
                this.confirmDeleteBudget(budgetId, budgetName, tempIsAuthoratative)
              },
              text: 'Delete',
              visible: !tempIsAuthoratative
            }
          }
        }
      })
    } else {
      console.log('else determineBudgetRows...')
    }
  },

  async reload() {
    this.loading = true

    try {
      this.$store.dispatch('budgets/getBudgetList').then(() => {
        this.determineBudgetRows()
      })
    } catch (e) {
      console.debug('error in method for retrieving budgets:' + e)
    }

    this.loading = false
  },

  processBudgetUpdate(budget) {
    this.toggle = true
    this.selectedBudget = budget
  },

  processAccountDetail(budget) {
    this.$router.push({
      name: 'budgetaccounts',
      params: {
        id: budget.budgetID,
        name: budget.name,
        fiscalYear: budget.fiscalYear
      }
    })
  },

  createBudgetModal() {
    if (this.isDebug == true) console.debug('in createBudgetModal...')
    this.selectedBudget = null
    this.toggle = true
  },

  async deleteBudget(budgetId, isAuthoratative) {
    if (this.isDebug == true) console.debug('...in deleteBudget id=' + budgetId)

    if (isAuthoratative == true) {
      notifyError('You cannot delete an authoratative budget.')
      return
    }

    if (budgetId <= 0) {
      notifyError('There was a problem deleting this budget.')
      return
    }

    try {
      this.$store.dispatch('budgets/deleteBudget', {
        budgetID: budgetId,
        done: () => {
          this.loading = false
          this.reload()
        }
      })
    } catch (e) {
      console.debug('There was an issue deleting your budget.')
    }

    this.loading = false
  },

  async copyBudget(copyFromBudget, fiscalYear) {
    if (!copyFromBudget || copyFromBudget == undefined) {
      notifyError('There was a problem duplicating this budget.')
      return
    }

    if (Number.isNaN(fiscalYear) || fiscalYear.length != 4) {
      notifyError('Your new fiscal year must be a number and a year.')
      return
    }

    if (this.isDebug == true)
      console.debug(
        '...in copyBudget' + JSON.stringify(copyFromBudget) + ', fiscalYear=' + fiscalYear
      )

    const payload = {
      budgetID: copyFromBudget.budgetID,
      hoaID: copyFromBudget.hoaID,
      fiscalYear: fiscalYear,
      name: copyFromBudget.name + ' COPY'
    }

    try {
      this.$store.dispatch('budgets/copyBudget', {
        payload: payload,
        done: () => {
          this.loading = false
          this.reload()
        }
      })
    } catch (e) {
      console.debug('There was an issue copying this budget.')
    }

    this.loading = false
  }
}
