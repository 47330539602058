import moment from 'moment'

var field = {
  width: '100px',
  display: 'inline-block',
  paddingRight: '6px',
  textAlign: 'left'
}

export const data = () => ({
  isDebug: true,
  toggle: false,
  loading: false,
  selectedBudgetAmount: null,
  budgetAmountsTableList: [],
  budgetAccountId: 0,
  budgetId: 0,
  budgetAccountName: '',
  budgetName: '',
  budgetFiscalYear: 0,
  budgetAmountsList: [],
  budgetAmountTotals: null,
  fiscalYearPeriodList: [],
  totals: {},
  formatDateFn: function(value) {
    return value && value != undefined
      ? moment(value.toString(), 'YYYY/MM/DD HH:mm:ss')
          .format('MM-DD-YYYY')
          .toString()
      : ''
  },

  filters: {
    show: false
  },

  pagination: {
    perPage: 12
  },

  columns: [
    {
      field: 'periodNumber',
      label: 'Period #',
      aria: 'Period #',
      width: '10%',
      numeric: true,
      sortable: true,
      searchable: true
    },
    {
      field: 'periodName',
      label: 'Period Name',
      aria: 'Period Name',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'startDate',
      label: 'Start Date',
      aria: 'Start Date',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'endDate',
      label: 'End Date',
      aria: 'End Date',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'amountBudgeted',
      label: 'Amount Budgeted',
      aria: 'Amount Budgeted',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'lastActual',
      label: 'Last Actual',
      aria: 'Last Actual',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'lastBudget',
      label: 'Last Budgeted',
      aria: 'Last Budgeted',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'lastActualsPer',
      label: 'Last Actuals Per.',
      aria: 'Last Actuals Per.',
      width: '10%',
      sortable: true,
      searchable: true
    }
  ],

  styles: {
    formRow: {
      padding: '10px',
      whiteSpace: 'nowrap'
    },
    filterButton: {
      float: 'right',
      marginTop: '4px'
    },
    amountField: Object.assign({}, field, {
      width: '125px'
    })
  }
})
